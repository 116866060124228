import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_button, {
      type: "primary",
      onClick: _ctx.toAddUser
    }, {
      default: _withCtx(() => [
        _createTextVNode("新增")
      ]),
      _: 1
    }, 8, ["onClick"]),
    _createVNode(_component_a_table, {
      style: {"margin-top":"10px"},
      bordered: "",
      rowKey: "Id",
      columns: _ctx.columns,
      "data-source": _ctx.dataSource,
      pagination: _ctx.pagination,
      loading: _ctx.loading,
      onChange: _ctx.handleTableChange
    }, {
      bodyCell: _withCtx(({ column, record }) => [
        (column.key === 'operation')
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createElementVNode("a", {
                onClick: ($event: any) => (_ctx.toEditUser(record))
              }, "编辑", 8, _hoisted_1),
              _createElementVNode("a", {
                style: {"color":"red","margin-left":"20px"},
                onClick: ($event: any) => (_ctx.toDelete(record.Id))
              }, "删除", 8, _hoisted_2)
            ], 64))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["columns", "data-source", "pagination", "loading", "onChange"]),
    _createVNode(_component_a_modal, {
      title: _ctx.modalTitle,
      visible: _ctx.visible,
      "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.visible) = $event)),
      "confirm-loading": _ctx.confirmLoading,
      maskClosable: false,
      onOk: _ctx.handleOk,
      onCancel: _ctx.closeModal
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form, {
          ref: "formRef",
          model: _ctx.formState,
          rules: _ctx.rules,
          "label-col": { span: 4 }
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form_item, {
              label: "名称",
              name: "Name"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _ctx.formState.Name,
                  "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formState.Name) = $event)),
                  autocomplete: "off"
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, {
              label: "登录名",
              name: "LoginName"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _ctx.formState.LoginName,
                  "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formState.LoginName) = $event))
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, {
              label: "密码",
              name: "Password"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _ctx.formState.Password,
                  "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formState.Password) = $event)),
                  autocomplete: "off"
                }, null, 8, ["value"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model", "rules"])
      ]),
      _: 1
    }, 8, ["title", "visible", "confirm-loading", "onOk", "onCancel"])
  ]))
}