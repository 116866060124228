import {fetchGet, fetchPut, fetchPost, fetchDelete} from '@/api/helpers.ts';

/**
 * 登录接口
 * @param data
 */
export function login(data: any) {
    return fetchPost('/api/user/login', data)
}

/**
 * 登录接口
 * @param data
 */
export function getX2serverInfo(data: any) {
    return fetchGet('/api/x2server/get', data)
}

/**
 * 获取x2server服务列表
 * @param data
 */
export function getX2ServerList(data: any) {
    return fetchGet('/api/X2Server/getList', data)
}

/**
 * 添加x2server服务
 * @param data
 */
export function addX2Server(data: any) {
    return fetchPost('/api/X2Server/create', data)
}

/**
 * 修改x2server服务
 * @param data
 */
export function updateX2Server(data: any) {
    return fetchPost('/api/X2Server/update', data)
}

/**
 * 删除x2server服务
 * @param data
 */
export function deleteX2Server(data: any) {
    return fetchGet('/api/X2Server/delete', data)
}

/**
 * 设置默认服务
 * @param data
 */
export function setDefaultServer(data: any) {
    return fetchGet('/api/user/setDefaultServer', data)
}

/**
 * 获取用户分页列表
 * @param data
 */
export function getUserList(data: any) {
    return fetchGet('/api/user/getList', data)
}

/**
 * 新增用户
 * @param data
 */
export function addUser(data: any) {
    return fetchPost('/api/user/create', data)
}

/**
 * 新增用户
 * @param data
 */
export function updateUser(data: any) {
    return fetchPost('/api/user/update', data)
}

/**
 * 删除用户
 * @param data
 */
export function deleteUser(data: any) {
    return fetchGet('/api/user/delete', data)
}
