
import {defineComponent, ref, onMounted,UnwrapRef,reactive,createVNode,nextTick} from "vue";
import {addUser, deleteUser, getUserList, updateUser} from "@/api/api";
import type { TableProps } from 'ant-design-vue';
import {Modal, message} from 'ant-design-vue';
import {
  ExclamationCircleOutlined
} from '@ant-design/icons-vue';

const columns = [
  {
    title: '登录名',
    dataIndex: 'LoginName',
  },
  {
    title: '名称',
    dataIndex: 'Name',
  },
  {
    title: '操作',
    align: 'center',
    width:'200px',
    key:'operation'
  }
]
interface FormState {
  Id: string | undefined;
  Name: string;
  LoginName: string;
  Password: string;
}
export default defineComponent({
  setup() {
    const formRef = ref();
    const loading = ref<boolean>(false)
    const dataSource = ref<any>([])
    const pagination = ref<any>({
      total: 0,
      current: 1,
      pageSize: 10,
    })
    const modalTitle = ref<string>("");
    const visible = ref<boolean>(false);
    const confirmLoading = ref<boolean>(false);
    const formState: UnwrapRef<FormState> = reactive({
      Id: undefined,
      Name: '',
      LoginName: '',
      Password: '',
    });
    let rules = ref<any>()

    onMounted(() => {
      getList()
    })

    const getList = async () => {
      const res: any = await getUserList({
        page:pagination.value?.current,
        count:pagination.value?.pageSize
      })
      dataSource.value = res.Items;
      pagination.value!.total = res.TotalCount
    }
    const handleTableChange: TableProps['onChange'] = (
        pag: any,
    ) => {
      pagination.value = pag
      getList()
    };
    const toAddUser = () => {
      rules.value = {
        Name: [
          {required: true, message: '请输入服务名', trigger: 'blur'},
        ],
        LoginName: [
          {required: true, message: '请输入登录名', trigger: 'blur'},
        ],
        Password: [
          {required: true, message: '请输入密码', trigger: 'blur'},
        ],
      }
      modalTitle.value = "新增用户"
      visible.value = true
    };
    const toEditUser = (data: FormState) => {
      rules.value = {
        Name: [
          {required: true, message: '请输入服务名', trigger: 'blur'},
        ],
        LoginName: [
          {required: true, message: '请输入登录名', trigger: 'blur'},
        ],
      }
      modalTitle.value = "修改服务"
      visible.value = true;
      nextTick(() => {
        Object.assign(formState, data)
      })
    };
    const handleOk = () => {
      formRef.value
          .validate()
          .then(async () => {
            confirmLoading.value = true;
            let res: any;
            if (formState.Id) {
              res = await updateUser(formState)
            } else {
              res = await addUser(formState)
            }
            confirmLoading.value = false;
            if (res.Result.toUpperCase() == "OK") {
              if (formState.Id) {
                message.success("修改成功！")
              } else {
                message.success("添加成功！")
              }
              getList();
              closeModal();
            }
          })
    }
    const closeModal = () => {
      formState.Id = undefined
      formRef.value.resetFields()
      visible.value = false;
    };
    const toDelete = (id: number) => {
      Modal.confirm({
        title: () => '是否删除该用户?',
        icon: () => createVNode(ExclamationCircleOutlined),
        okText: () => '是',
        okType: 'danger',
        cancelText: () => '否',
        async onOk() {
          const res: any = await deleteUser({id})
          if (res.Result.toUpperCase() == "OK") {
            message.success("删除成功！")
            getList();
          }
        },
      });
    }

    return {
      formRef,
      loading,
      dataSource,
      pagination,
      visible,
      modalTitle,
      confirmLoading,
      formState,
      rules,
      columns,
      handleTableChange,
      toAddUser,
      toEditUser,
      handleOk,
      closeModal,
      toDelete,
    }
  }
})
